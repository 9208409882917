import axios from "axios";

const createApiClient = (version = "v2") => {
  const api = axios.create({
    baseURL: `http://localhost:5001/api/${version}/bigjogos/`,
    headers: {
      "x-access-token": localStorage.getItem("token"),
    },
  });

  api.interceptors.response.use(
    (response) => response,
    (error) => {
      // handle error
      if (error.response) {
        return Promise.reject(error.response);
      }
    }
  );

  return api;
}


export default {
  publishersSenders: {
    list: (eventId) => createApiClient().get(`publishersGames/publishers/${eventId}`),
    create: (newPublihser) => createApiClient().post("publishersGames/publisher", newPublihser),
    update: (publisher) => createApiClient().put(`publishersGames/publisher/${publisher.id}`, publisher),
    sendToken: (publisherLogin) => createApiClient().post("publishersGames/send-token", publisherLogin),
    validateToken: (publisherLogin) => createApiClient().post("publishersGames/validate-token", publisherLogin),
    sendGame: (game) => {
      const publisherToken = localStorage.getItem("publisherToken"); 
      const api = createApiClient();
      api.defaults.headers["x-publisher-token"] = publisherToken;
      return api.post("publishersGames/send-game", game)
    }
  }
}
